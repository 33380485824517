import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadPageData } from "../../actions/cms";
import { RootState } from "../../reducers";
import client from "../../Client";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';
import {Modal,Button} from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Gallery: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [description, setDescription] = useState("");
  const [sliderInMobile, setSliderInMobile] = useState(false);
  const [layoutType, setLayoutType] = useState("");
  const [galleryitems,setGalleryitems] = useState([]);
  const [moreGalleryitemsLength,setGalleryitemsLength] = useState(0);
  const[currentImageIndex,setCurrentImageIndex]=useState(null);
  const[rowType,setRowType]=useState(4);
  const[columnClass,setColumnClass]=useState(""); 
  const[rowClass,setRowClass]=useState("");
  const[sectionClass,setSectionClass]=useState("gallery");
  const [entryId, setEntryId] = useState('');
  const [dynamicClass, setDynamicClass] = useState('');  //slider
  const[modal,setModal]=useState(false);

  useEffect(() => {
      if (componentData.sliderInMobile != undefined ) {
        setSliderInMobile(componentData.sliderInMobile); 
      }

      if (componentData.description != undefined && componentData.description != "") {
        setDescription(componentData.description);
      }

      if (componentData.entryId != "" && componentData.entryId != undefined) {
       setEntryId("content-"+componentData.entryId);
      }

      if (componentData.rowType != undefined && componentData.rowType != "") {
        setRowType(componentData.rowType);
        if(componentData.rowType==1)
        {
          setColumnClass("col-12");
          setRowClass("row-one");
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType==2)
        {
          setColumnClass("col-12 col-md-6");
          setRowClass("row-two");
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType==3)
        {
          setColumnClass("col-md-4 col-6");
          setRowClass("row-three");
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType=="3-equalHeight")
        {
          setColumnClass("col-md-4 col-sm-6");
          setRowClass("row-3-equalHeight");
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType=="3-equalHeight-square")
        {
          setColumnClass("col-12 col-sm-4");
          setRowClass("row-3-equalHeight-square");
          setSectionClass("col-3-square");  
        }
        else if(componentData.rowType==4)
        {
          setColumnClass("col-md-3 col-6");
          setRowClass("row-four");
          setSectionClass("gallery four-col");  
          
        }
        else if(componentData.rowType==5)
        {
          setColumnClass("col-5");
          setRowClass("row-five");
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType=="Trio-Left")
        {
          setColumnClass("trio-left-image");
          setRowClass("Trio-Left");
          setSectionClass("gallery trio-left-gallery");
        }
        else if(componentData.rowType=="Trio-Right")
        {
          setColumnClass("trio-right-image")
          setRowClass("Trio-Right")
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType=="One-With-Offset")
        {
          setColumnClass("col-md-10")
          setRowClass("one-with-offset")
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType=="Single-Image")
        {
          setColumnClass("col-md-7")
          setRowClass("single-image");
          setSectionClass("gallery partners five-col");
        }
        else if(componentData.rowType=="4-Col-Landscape")
        {
          setColumnClass("col-6 col-md-3")
          setRowClass("Col-4-Landscape")
          
          
        }
        else if(componentData.rowType=="2-Column-Portrait")
        {
          setColumnClass("col-12 col-md-6");
          setRowClass("row-two-with-portrait");
          setSectionClass("gallery");
        }
      }

    

      if (componentData.galleryItems != undefined && componentData.galleryItems != "") {
        let allGalleryItems: any = [];
        componentData.galleryItems.map(async(item:any,i:any)=>
        {
          await client
          .getEntry(item.sys.id, {locale: currLocale})
            .then(async (entry: any) => {

             if (entry != "" && entry != undefined) {
             let singleGalleryItem = {
              index: i,
              description:"",
              image:"",
              imageAltText:"",
              ctaClass: "read-more-link",
              ctaTitle: "",
              ctaAriaLabel: "",
              ctaLink:"",
              ctaButtonColor:"",
              ctaButtonTextColor:"",
              ctaTextColor:"",
              ctaUnderlineColor:"",
              ctaZigzaglineColor:"",
              hoverColor:"",
              openLinkInNewTab: "",
              handlename: "",
              handleIcon: "",

              };
             if (entry.fields.description != '' && entry.fields.description != undefined) {
                  singleGalleryItem["description"]=entry.fields.description;
             }
             if (entry.fields.handleName != '' && entry.fields.handleName != undefined) {
                  singleGalleryItem["handlename"]=entry.fields.handleName;
             }
             if (entry.fields.handleIcon != '' && entry.fields.handleIcon != undefined) {
                  singleGalleryItem["handleIcon"]=entry.fields.handleIcon;
             }
             if (entry.fields.image != '' && entry.fields.image != undefined) {
                  singleGalleryItem["image"]=entry.fields.image.fields.file.url;
                  singleGalleryItem['imageAltText']=entry.fields.image.fields.title;
             }
             if (entry.fields.cta != '' && entry.fields.cta != undefined) {
                if(entry.fields.cta.fields.ctaType!="" && entry.fields.cta.fields.ctaType!=undefined)
                {
                  let ctaType=entry.fields.cta.fields.ctaType;
                  if (ctaType === 'Primary Button') {
                  singleGalleryItem["ctaClass"]='btn btn-primary';
                  }
                  else if (ctaType === 'Secondary Button') {
                    singleGalleryItem["ctaClass"]='btn btn-secondary';
                  }
                  else if (ctaType === 'Small Text CTA Black') {
                    singleGalleryItem["ctaClass"]='small-text-cta cta-black';
                  }
                  else if (ctaType === 'Small Text CTA White') {
                    singleGalleryItem["ctaClass"]='small-text-cta cta-white';
                  }
                  else if (ctaType === 'Large Text CTA Black') {
                    singleGalleryItem["ctaClass"]='large-text-cta cta-black';
                  }
                  else if (ctaType === 'Large Text CTA White') {
                    singleGalleryItem["ctaClass"]='large-text-cta cta-white';
                  }
                  else if (ctaType === 'Icon Text CTA') {
                    singleGalleryItem["ctaClass"]='icon-text-cta';
                  }
                  else if (ctaType === 'Read More CTA') {
                    singleGalleryItem["ctaClass"]='read-more-link';
                  }
                }
                if (entry.fields.cta.fields.title != '' && entry.fields.cta.fields.title != undefined) {
                  singleGalleryItem["ctaTitle"]=entry.fields.cta.fields.title;
                }
                if (entry.fields.cta.fields.ariaLabel != '' && entry.fields.cta.fields.ariaLabel != undefined) {
                  singleGalleryItem["ctaAriaLabel"]=entry.fields.cta.fields.ariaLabel;
                }
                if (entry.fields.cta.fields.alias != '' && entry.fields.cta.fields.alias != undefined) {
                  singleGalleryItem["ctaLink"]=entry.fields.cta.fields.alias;
                }
                 if (entry.fields.cta.fields.ctaTextColor !== undefined && entry.fields.cta.fields.ctaTextColor !== "") {
                singleGalleryItem["ctaTextColor"] = entry.fields.cta.fields.ctaTextColor;
              }

               if (entry.fields.cta.fields.ctaZigzaglineColor !== undefined && entry.fields.cta.fields.ctaZigzaglineColor !== "") {
                singleGalleryItem["ctaZigzaglineColor"] = entry.fields.cta.fields.ctaZigzaglineColor;
              }

               if (entry.fields.cta.fields.ctaUnderlineColor !== undefined && entry.fields.cta.fields.ctaUnderlineColor !== "") {
                singleGalleryItem["ctaUnderlineColor"] = entry.fields.cta.fields.ctaUnderlineColor;
              }

               if (entry.fields.cta.fields.ctaButtonColor !== undefined && entry.fields.cta.fields.ctaButtonColor !== "") {
                singleGalleryItem["ctaButtonColor"] = entry.fields.cta.fields.ctaButtonColor;
              }

               if (entry.fields.cta.fields.ctaButtonTextColor !== undefined && entry.fields.cta.fields.ctaButtonTextColor !== "") {
                singleGalleryItem["ctaButtonTextColor"] = entry.fields.cta.fields.ctaButtonTextColor;
              }
               if (entry.fields.cta.fields.hoverColor !== undefined && entry.fields.cta.fields.hoverColor !== "") {
                singleGalleryItem["hoverColor"] = entry.fields.cta.fields.hoverColor;
              }

              if (entry.fields.cta.fields.openLinkInNewTab != '' && entry.fields.cta.fields.openLinkInNewTab != undefined && entry.fields.cta.fields.openLinkInNewTab === true) {
                singleGalleryItem["openLinkInNewTab"]= "_blank";
              }
             }
               allGalleryItems.push(singleGalleryItem);
               allGalleryItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);
              await setGalleryitems(allGalleryItems);
              await setGalleryitemsLength(allGalleryItems.length);

              }

            })
        })
      }

  },[]);
  // row-3-equalHeight-square

  
  const showModal=async(index:any)=>
  {
    setCurrentImageIndex(index);
    setModal(true);
    
  }
  const slickSettings = {     
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,        
          slidesToScroll: 1
        }
      },
      
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1
        }
      }
    ]
  };

  const onShowItemModal = (items: any, index: number) => {
    if (items.description) {
      showModal(index);
    }
  };

  const onExitModal = () => {
    setModal(false);
  }

  useEffect(()=>{
    if (modal) {
      document.addEventListener('keydown', (ev) => {
        if (ev.key === "Escape") {
          onExitModal();
        }
      })
    } else {
      document.removeEventListener('keydown', (ev) => {
        if (ev.key === "Escape") {
          onExitModal();
        }
      });
    }
  },[modal]);

  return (
    <>
      <section className={sectionClass}>
        <div className="container">
          
          <div className={"row flex-center "+rowClass+" "+(sliderInMobile==true?"mobile-hide":"")} style={{justifyContent:"center"}}>
            {galleryitems?galleryitems.map((items:any,index)=>
                
                <div className={columnClass} data-toggle="modal" data-target="#galleryModal">
                  <div className="inner-wrapper">
                    <div className="inner">
                      {items.image?
                      <LazyLoadImage 
                        onClick={() => {
                          onShowItemModal(items, index);
                        }} 
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            onShowItemModal(items, index);
                          }
                        }}
                        width = '274' 
                        height = '172' 
                        src={items.image} 
                        alt={items.imageAltText?items.imageAltText:""} 
                        role="button"
                        tabIndex={0}
                      />:null}
                      {items.handlename ?
                        <div className="user-name-block">
                        <div className="name">{items.handlename}</div>
                        {items.handleIcon=="Instagram"? <div className="icon"><i className="fa fa-instagram" aria-hidden="true"></i></div> : null}
                      </div>
                      : null }
                      </div>
                    </div>
                  </div>):null
              }                     
            {description?<small><ReactMarkdown allowDangerousHtml source={description} disallowedTypes={['paragraph']} unwrapDisallowed /></small>:null}
          </div>
            
         <div className={"row flex-center with-slider desktop-hide "+rowClass+" "+(sliderInMobile==false?"mobile-hide":"")} style={{justifyContent:"center"}}>
             {galleryitems.length>0?
              <Slider {...slickSettings}>
                {galleryitems?galleryitems.map((item:any,index)=>
                        <div className="slide-item">
                          <div className="inner-wrapper">
                            <div className="inner">
                              {item.image?<LazyLoadImage onClick={()=> { if(item.description){showModal(index)} } } width = '373' height = '373' src={item.image} alt={item.imageAltText?item.imageAltText:""}></LazyLoadImage>:null}
                              {item.handlename?
                                <div className="user-name-block">
                                <div className="name">{item.handlename}</div>
                                {item.handleIcon=="Instagram"?<div className="icon"><i className="fa fa-instagram" aria-hidden="true"></i></div> : null}
                              </div>
                              : null }
                              </div>
                            </div>
                          </div>):null
                      }            
              </Slider>
            :null}  
            </div>
             
        </div>

      </section>  

      <Modal
        size="lg"
        show={modal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{backgroundColor:'transparent'}}
      >
        <Modal.Body className="team-modal with-gallery">
          <div className="modal-body">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(event)=>{event.preventDefault();setModal(false)}}></button>
            
              {galleryitems ? galleryitems.map(
                        (item : any, key) => {
                            if(key == currentImageIndex){
                              return <>
                              <style dangerouslySetInnerHTML=
                                {{__html: `
                                  .large-text-cta.new.cta-white.${entryId} i,
                                  .large-text-cta.new.cta-white.${entryId} {
                                     color:${item.ctaTextColor};            
                                  }                      

                                 .large-text-cta.new.cta-white.${entryId}:hover  i,
                                 .large-text-cta.new.cta-white.${entryId}:hover {
                                      text-decoration: none;
                                      color:${item.hoverColor};
                                  }                      

                                  .large-text-cta.new.cta-white.${entryId} span {
                                    background: ${item.ctaUnderlineColor};
                                  }                      

                                  .large-text-cta.new.cta-white.${entryId}:hover svg path {
                                    stroke: ${item.ctaZigzaglineColor};
                                  }                      

                                   .large-text-cta.new.cta-black.${entryId} i,
                                  .large-text-cta.new.cta-black.${entryId} {
                                     color:${item.ctaTextColor};
                                  }                      

                                  .large-text-cta.new.cta-black.${entryId}:hover i,
                                 .large-text-cta.new.cta-black.${entryId}:hover {
                                      text-decoration: none;
                                      color:${item.hoverColor};
                                  }                      

                                   .large-text-cta.new.cta-black.${entryId} span {
                                    background: ${item.ctaUnderlineColor};
                                  }                      

                                  .large-text-cta.new.cta-black.${entryId}:hover svg path {
                                    stroke: ${item.ctaZigzaglineColor};
                                  }                      

                                  .small-text-cta.new.cta-black.${entryId} i,
                                  .small-text-cta.new.cta-black.${entryId} {
                                     color:${item.ctaTextColor};
                                  }                      

                                  .small-text-cta.new.cta-black.${entryId}:hover i,
                                  .small-text-cta.new.cta-black.${entryId}:hover {
                                    text-decoration: none;
                                     color:${item.hoverColor};
                                  }                      

                                   .small-text-cta.new.cta-black.${entryId} span {
                                    background: ${item.ctaUnderlineColor};
                                  }                      

                                  .small-text-cta.new.cta-black.${entryId}:hover svg path {
                                    stroke: ${item.ctaZigzaglineColor};
                                  }                      

                                  .small-text-cta.new.cta-white.${entryId} i,
                                  .small-text-cta.new.cta-white.${entryId} {
                                     color:${item.ctaTextColor};
                                  }
                                  .small-text-cta.new.cta-white.${entryId}:hover i,
                                  .small-text-cta.new.cta-white.${entryId}:hover {
                                    text-decoration: none;
                                     color:${item.hoverColor};
                                  }                      

                                   .small-text-cta.new.cta-white.${entryId} span {
                                    background: ${item.ctaUnderlineColor};
                                  }                      

                                  .small-text-cta.new.cta-white.${entryId}:hover svg path {
                                    stroke: ${item.ctaZigzaglineColor};
                                  }                      

                                  .btn.btn-primary.${entryId}:active {
                                    background:${item.ctaButtonColor} !important;
                                    border-color: ${item.ctaButtonColor} !important;
                                  }                      

                                 .btn.btn-primary.${entryId}:focus,
                                 .btn.btn-primary.${entryId}:focus:active,
                                 .btn.btn-primary.${entryId}:visited{
                                     background:${item.hoverColor};
                                    color:${item.ctaButtonTextColor};
                                    border-color: ${item.hoverColor};   
                                 }                     

                                 .btn.btn-primary.${entryId} {
                                     background:${item.ctaButtonColor};
                                    color:${item.ctaButtonTextColor};
                                    border-color: ${item.ctaButtonColor};
                                 }                      

                                  .btn.btn-primary.${entryId}:hover{
                                       background:${item.hoverColor};
                                       border-color:${item.hoverColor};
                                  }                      

                                  .btn.btn-secondary.${entryId}:active {
                                    background:${item.ctaButtonColor} !important;
                                    border-color: ${item.ctaButtonColor} !important;
                                  }                      

                                  .btn.btn-secondary.${entryId}:focus,
                                  .btn.btn-secondary.${entryId}:focus:active,
                                  .btn.btn-secondary.${entryId}:visited{
                                      background:${item.hoverColor};
                                     color:${item.ctaButtonTextColor};
                                     border-color:${item.hoverColor};   
                                  }                      

                                  .btn.btn-secondary.${entryId} {
                                      background:${item.ctaButtonColor};
                                     color:${item.ctaButtonTextColor};
                                     border-color:${item.ctaButtonColor};
                                  }

                                   .btn.btn-secondary.${entryId}:hover{
                                       background:${item.hoverColor};
                                       border-color:${item.hoverColor};
                                  }                      

                                  .read-more-link.${entryId} {
                                    color: ${item.ctaTextColor};
                                    text-decoration-color: ${item.ctaUnderlineColor};
                                  }
                                  .read-more-link.${entryId}:hover {
                                    color: ${item.hoverColor};
                                    text-decoration-color: ${item.hoverColor};
                                  }                      

                                  .icon-text-cta.${entryId},a{
                                    color: ${item.ctaTextColor};
                                    text-decoration-color: ${item.ctaUnderlineColor};
                                  }
                                  
                                  .icon-text-cta.${entryId}:hover {
                                    color: ${item.hoverColor};
                                    text-decoration-color: ${item.hoverColor};
                                  }                                                                          `,
                                }}
                              />
                              <div className="model-left">      
                                    <div className="img-outer">
                                       {item.image ?<img src={item.image} width = '330' height = '330' alt={item.imageAltText?item.imageAltText:""} />:null}
                                    </div>
                               </div>
                                <div className="model-right">
                                    {item.description?<ReactMarkdown allowDangerousHtml source={item.description}></ReactMarkdown> :null}
                                    {item.ctaTitle ?
                                        <a className={item.ctaClass+" new"+" "+entryId} target={item.openLinkInNewTab} href={addLangKeyPath(item.ctaLink)} aria-label={item.ctaAriaLabel}>{item.ctaTitle}
                                          <i className="fa fa-caret-right" aria-hidden="true"></i>          
                                          <span className="svg-outer">
                                            <svg width="387" height="12" viewBox="0 0 387 12">
                                              <defs>
                                                <clipPath>
                                                  <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                                                </clipPath>
                                              </defs>
                                              <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                                                <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                                              </g>
                                            </svg>          
                                          </span>
                                          <span>           
                                          </span>
                                        </a>
                                      : ""
                                    }
                                </div>
                              </>
                            }
                        }                
              ) : ""}
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSiteData: () => dispatch(loadPageData()),
});

const mapStateToProps = (state: RootState) => ({
  header: state.site.header,
});

Gallery.defaultProps = {
  fetchSiteData: () => {},
  header: {},
};

export { Gallery };

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
