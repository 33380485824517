import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import ReactTooltip from 'react-tooltip';
import { getLocale } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';
import * as Tooltip from '@radix-ui/react-tooltip';

interface ITabsProps {
  tab1: ITab;
  tab2: ITab;
  toolTipIcon: string;
  toolTipAltText: string;
  underlineColor: string;
}

interface ITab {
  button: ITabButton;
  content: ITabContent;
}

interface ITabContent {
  value: never[];
}

interface ITabButton {
  title: string;
  subTitle: string;
}

const FacilitiesTabsBlock: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [title, setTitle] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [whatWeAcceptHeading,setWhatWeAcceptHeading] = useState('');
  const [whatWeAcceptSubHeading,setWhatWeAcceptSubHeading] = useState('');
  const [whatWeAcceptDescription,setWhatWeAcceptDescription] = useState('');
  const [whatWeDontAcceptHeading, setWhatWeDontAcceptHeading] = useState('');
  const [whatWeDontAcceptSubHeading,setWhatWeDontAcceptSubHeading] = useState('');
  const [whatWeDontAcceptDescription,setWhatWeDontAcceptDescription] = useState('');
  const [sectionBottomDescription, setSectionBottomDescription] = useState('');
  const [sectionLowerBottomContent, setSectionLowerBottomContent] = useState('');
  const [allAcceptedItems, setAllAcceptedItems] = useState([]);
  const [allWeAcceptedItemsLength, setAllWeAcceptedItemsLength] = useState(0);
  const [allWeDontAcceptedItems, setAllWeDontAcceptedItems] = useState([]);
  const [allWeDontAcceptedItemsLength, setAllWeDontAcceptedItemsLength] = useState(0);
  const [toolTipIcon,setToolTipIcon] = useState("");
  const [toolTipAltText,setToolTipAltText] = useState("");
  const [underlineColor,setUnderlineColor] = useState("#DA291C");


   useEffect(() => {

    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }

    if (componentData.tooltipIcon != '' && componentData.tooltipIcon != undefined) {
      let tooltipIcon= componentData.tooltipIcon.fields!==undefined? componentData.tooltipIcon.fields.file.url:""
      let tooltipAltText= componentData.tooltipIcon.fields!==undefined? componentData.tooltipIcon.fields.title:""
      setToolTipIcon(tooltipIcon);
      setToolTipAltText(tooltipAltText)
    }

    if (componentData.backgroundColor != '' && componentData.backgroundColor != undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.underlineColor != '' && componentData.underlineColor != undefined) {
      setUnderlineColor(componentData.underlineColor);
    }

    if (componentData.whatWeAcceptHeading != '' && componentData.whatWeAcceptHeading != undefined) {
      setWhatWeAcceptHeading(componentData.whatWeAcceptHeading);
    }

    if (componentData.whatWeAcceptSubHeading != '' && componentData.whatWeAcceptSubHeading != undefined) {
      setWhatWeAcceptSubHeading(componentData.whatWeAcceptSubHeading);
    }

    if (componentData.whatWeAcceptDescription != '' && componentData.whatWeAcceptDescription != undefined) {
      setWhatWeAcceptDescription(componentData.whatWeAcceptDescription);
    }

    if (componentData.whatWeDontAcceptHeading != '' && componentData.whatWeDontAcceptHeading != undefined) {
      setWhatWeDontAcceptHeading(componentData.whatWeDontAcceptHeading);
    }

    if (componentData.whatWeDontAcceptSubHeading != '' && componentData.whatWeDontAcceptSubHeading != undefined) {
      setWhatWeDontAcceptSubHeading(componentData.whatWeDontAcceptSubHeading);
    }

    if (componentData.whatWeDontAcceptDescription != '' && componentData.whatWeDontAcceptDescription != undefined) {
      setWhatWeDontAcceptDescription(componentData.whatWeDontAcceptDescription);
    }

    if (componentData.sectionBottomDescription != '' && componentData.sectionBottomDescription != undefined) {
      setSectionBottomDescription(componentData.sectionBottomDescription);
    }

    if (componentData.sectionLowerBottomContent != '' && componentData.sectionLowerBottomContent != undefined) {
      setSectionLowerBottomContent(componentData.sectionLowerBottomContent);
    }

    if (componentData.whatWeAcceptSection != '' && componentData.whatWeAcceptSection != undefined) 
    {
      let allAcceptedItems: any = [];
      componentData.whatWeAcceptSection.map(async(item: any,i:any) => {
      let singleAcceptedItem = {
            index: i,
            title: "",
            extraInformation: "",
            image: "",
            imageAltText:""
          };
        await client
          .getEntry(item.sys.id, {locale: currLocale})
          .then(async(entry: any) => {
            if (entry.fields.title != "" && entry.fields.title != undefined) {
              singleAcceptedItem["title"] = entry.fields.title;
            }

             if (entry.fields.extraInformation != undefined && entry.fields.extraInformation != "") {
                singleAcceptedItem["extraInformation"] = entry.fields.extraInformation;
             
            }

            if (entry.fields.image.fields.file.url != "" && entry.fields.image.fields.file.url != undefined)
            {
             singleAcceptedItem["image"] = entry.fields.image.fields.file.url;
             singleAcceptedItem["imageAltText"]= entry.fields.image.fields.title;
            }
            await allAcceptedItems.push(singleAcceptedItem);
            allAcceptedItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);

            await setAllAcceptedItems(allAcceptedItems);
            await setAllWeAcceptedItemsLength(allAcceptedItems.length);
            
          })
          .catch(console.error);
      });
    }

    if (componentData.whatWeDontAccept != '' && componentData.whatWeDontAccept != undefined) 
    {
    let allWeDontAcceptedItems: any = [];
      componentData.whatWeDontAccept.map(async(item: any,i:any) => {
      let singleDontAcceptedItem = {
            index: i,
            title: "",
            extraInformation: "",
            image: "",
            imageAltText:""
          };
        await client
          .getEntry(item.sys.id, {locale: currLocale})
          .then(async(entry: any) => {
           if (entry.fields.title != "" && entry.fields.title != undefined) {
              singleDontAcceptedItem["title"] = entry.fields.title;
            }

             if (entry.fields.extraInformation != undefined && entry.fields.extraInformation != "") {
                singleDontAcceptedItem["extraInformation"] = entry.fields.extraInformation;
             
            }

            if (entry.fields.image.fields.file.url != "" && entry.fields.image.fields.file.url != undefined) {
             singleDontAcceptedItem["image"] = entry.fields.image.fields.file.url;
             singleDontAcceptedItem["imageAltText"]= entry.fields.image.fields.title;
            }
            await allWeDontAcceptedItems.push(singleDontAcceptedItem);
            allWeDontAcceptedItems.sort((a: any, b: any) => (a.index > b.index) ? 1 : -1);

            await setAllWeDontAcceptedItems(allWeDontAcceptedItems);
            await setAllWeDontAcceptedItemsLength(allWeDontAcceptedItems.length);
          })
          .catch(console.error);
      });
    }

   },[]);

    return (
        <>
        <div className="facilities-tabs-block tab-style mb-5" style={{backgroundColor: backgroundColor}}>
            <div className="container">
                
                {title?<h4 className="text-center" dangerouslySetInnerHTML={{__html: title}} />:null}
                <Tabs 
                  tab1 = {
                    {
                      button: {
                        title: whatWeAcceptHeading, 
                        subTitle: whatWeAcceptSubHeading
                      }, 
                      content: {
                        value: allAcceptedItems
                      }
                    }
                  }
                  tab2 = {
                    {
                      button: {
                        title: whatWeDontAcceptHeading, 
                        subTitle: whatWeDontAcceptSubHeading
                      }, 
                      content: {
                        value: allWeDontAcceptedItems
                      }
                    }
                  }
                  toolTipIcon = {toolTipIcon}
                  toolTipAltText = {toolTipAltText}
                  underlineColor={underlineColor}
                />
                
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    {whatWeAcceptSubHeading?<p className="text-center desktop-hide mobile-hide tb-space">{whatWeAcceptSubHeading}</p>:null}
                    <div className="heading-row text-center mt-5 desktop-hide">	
                      <h6 className="small-text" style={{ borderBottom: `4px solid ${underlineColor}` }}>{whatWeAcceptHeading}
                      <span>{whatWeAcceptSubHeading}</span>                      
                      </h6>                                                    
                    </div>
                  
                    <div className="facility-wrap mt-4 desktop-hide">
                        <ul>
                        {allAcceptedItems.map(({title,extraInformation,image,imageAltText},i)=>
                            <li>
                              <div className="inner-wrap">
                                {image?<span className="icon-wrap"><img width = '120' height = '120' src={image} alt={imageAltText?imageAltText:""}/></span>:null}
                                  {extraInformation?<span data-tip data-for={"accept"+i} className="add-icon"  >
                                <img className="smallicon" width = '24' height = '24' src={toolTipIcon!=="" ? toolTipIcon:require("../../images/plus-icon.svg")} alt={toolTipAltText} />
                                  <ReactTooltip id={"accept"+i} place="bottom" effect="solid">
                                  {extraInformation}
                                </ReactTooltip> 
                                </span>:null}
                              </div>
                                {title?<p>{title}</p>:null}
                            
                            </li>
                        )}  
                        </ul>
                    </div>
                  </div>
                  <div className="tab-pane" id="tabs-2" role="tabpanel">
                    {whatWeDontAcceptSubHeading?<p className="text-center desktop-hide mobile-hide tb-space">{whatWeDontAcceptSubHeading}</p>:null}

                    <div className="heading-row text-center mt-3 desktop-hide">	
                      <h6 className="small-text" style={{ borderBottom: `4px solid ${underlineColor}` }}>{whatWeDontAcceptHeading}
                      <span>{whatWeDontAcceptSubHeading}</span>
                      </h6>                          
                    </div>
                  
                    <div className="facility-wrap mt-4 desktop-hide">
                        <ul>
                        {allWeDontAcceptedItems.map(({title,extraInformation,image,imageAltText},i)=>
                            <li>
                              <div className="inner-wrap">
                                {image?<span className="icon-wrap"><img width = '120' height = '120' src={image} alt={imageAltText?imageAltText:""}/></span>:null}
                                {extraInformation!==""?<a data-tip data-for={"notAccept"+i} className="add-icon"  >
                                <img className="smallicon" width = '24' height = '24' src={toolTipIcon!=="" ? toolTipIcon:require("../../images/plus-icon.svg")} alt={toolTipAltText} />
                                  <ReactTooltip id={"notAccept"+i} place="bottom" effect="solid">
                                  {extraInformation}
                                </ReactTooltip> 
                                </a>:null}
                                </div>
                                {title?<p>{title}</p>:null}
                            </li>
                        )}  
                        </ul>

                    </div>
                  </div>
                  
                    {sectionBottomDescription?<p className="mt-4 text-center"><ReactMarkdown allowDangerousHtml source={sectionBottomDescription} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                    {sectionLowerBottomContent?<p className="small-text text-grey text-center"><ReactMarkdown allowDangerousHtml source={sectionLowerBottomContent} disallowedTypes={['paragraph']} unwrapDisallowed /></p>:null}
                </div>

            </div>
        </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FacilitiesTabsBlock.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FacilitiesTabsBlock };

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesTabsBlock);

export function Tabs(props:ITabsProps) {
  const TAB_COUNT = 2;

  const {tab1, tab2, underlineColor} = {...props};

  const [activeTab, setActiveTab] = useState(1);
  const [showTab, setShowTab] = useState(1);

  const tabPanels: any = useRef([]);

  const focusInput = (i: number) => {
    tabPanels.current[i - 1].focus();
  };

  const moveFocusToPreviousTab = () => {
    let tabToFocus = activeTab - 1;

    if (tabToFocus < 1) {
      tabToFocus = TAB_COUNT;
    }

    setActiveTab(tabToFocus);
    focusInput(tabToFocus);
  };

  const moveFocusToTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
    focusInput(tabIndex);
  };

  const moveFocusToNextTab = () => {
    let tabToFocus = activeTab + 1;
    console.log(tabToFocus);

    if (tabToFocus > TAB_COUNT) {
      tabToFocus = 1;
    } 
    
    setActiveTab(tabToFocus);
    focusInput(tabToFocus);
  };

  const onKeyDownTab = (event: any) => {
    let preventsDefault = false;

    switch (event.key) {
      case "Enter":
        const currentTabIndex = tabPanels.current.findIndex(
          (item: object) => item === event.target
        );
        setShowTab(currentTabIndex + 1);
        break;

      case "ArrowLeft":
        moveFocusToPreviousTab();
        preventsDefault = true;
        break;

      case "ArrowRight":
        moveFocusToNextTab();
        preventsDefault = true;
        break;

      case "Home":
        moveFocusToTab(1);
        preventsDefault = true;
        break;

      case "End":
        moveFocusToTab(TAB_COUNT);
        preventsDefault = true;
        break;

      default:
        break;
    }

    if (preventsDefault) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  useEffect(()=>{
    setShowTab(activeTab);
  },[activeTab])

  return (
    <div className="nav nav-tabs mt-4 mb-4 mobile-hide tab-content" role="tablist" aria-controls="tablist-1">
      <button
        role="tab"
        onClick={() => setActiveTab(1)}
        onKeyDown={onKeyDownTab}
        aria-selected={activeTab === 1 ? "true" : "false"}
        aria-controls="tabpanel-1"
        tabIndex={activeTab === 1 ? 0 : -1}
        ref={(ref) => {
          if (ref) tabPanels.current[0] = ref;
        }}
        className="nav-item"
        style={{border: 'none', backgroundColor: 'unset'}}
      >
          {tab1.button.subTitle||tab1.button.subTitle?<div className={`nav-link ${showTab === 1 ? 'active' : ''}`} style={showTab === 1 ? { borderBottom: `4px solid ${underlineColor}` } : {}}><div dangerouslySetInnerHTML={{ __html: tab1.button.title }}>
            
          </div>
          <small>{tab1.button.subTitle}</small>
          </div>:null}
      </button>
      <button
        role="tab"
        onClick={() => setActiveTab(2)}
        onKeyDown={onKeyDownTab}
        aria-selected={activeTab === 2 ? "true" : "false"}
        aria-controls="tabpanel-2"
        tabIndex={activeTab === 2 ? 0 : -1}
        ref={(ref) => {
          if (ref) tabPanels.current[1] = ref;
        }}
        className="nav-item"
        style={{border: 'none', backgroundColor: 'unset'}}
      >
         {tab2.button.subTitle||tab2.button.subTitle?<div className={`nav-link ${showTab === 2 ? 'active' : ''}`} style={showTab === 2 ? { borderBottom: `4px solid ${underlineColor}` } : {}}><div dangerouslySetInnerHTML={{ __html: tab2.button.title }}>
          </div>
          <small>{tab2.button.subTitle}</small>
          </div>:null}
      </button>

      <div hidden={showTab !== 1} className="facility-wrap mt-4">
          <ul>
          {tab1.content.value.map(({title,extraInformation,image,imageAltText},i)=>
              <li>
                <div className="inner-wrap">
                  {image?<span className="icon-wrap"><img width = '120' height = '120' src={image} alt={imageAltText?imageAltText:""}/></span>:null}
                    {extraInformation?<span data-tip data-for={"accept"+i} className="add-icon"  >
                    <img className="smallicon" width = '24' height = '24' src={props.toolTipIcon!=="" ? props.toolTipIcon:require("../../images/plus-icon.svg")} alt={props.toolTipAltText} />
                    <ReactTooltip id={"accept"+i} place="bottom" effect="solid">
                      {extraInformation}
                    </ReactTooltip> 
                  </span>:null}
                </div>
                  {title?<p>{title}</p>:null}
              </li>
          )}  
          </ul>
      </div>
      <div hidden={showTab !== 2} className="facility-wrap mt-4">
        <ul>
          {tab2.content.value.map(({title,extraInformation,image,imageAltText},i)=>
              <li>
                <div className="inner-wrap">
                  {image?<span className="icon-wrap"><img width = '120' height = '120' src={image} alt={imageAltText?imageAltText:""}/></span>:null}
                    {extraInformation?<span data-tip data-for={"accept"+i} className="add-icon"  >
                    <Tooltip.Provider delayDuration={100}>
                      <Tooltip.Root>
                        <Tooltip.Trigger className="tooltip-trigger-class" disabled>
                          <span tabIndex={0}>
                            <img className="smallicon" width = '24' height = '24' src={props.toolTipIcon!=="" ? props.toolTipIcon:require("../../images/plus-icon.svg")} alt={props.toolTipAltText}/>
                          </span>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                          <Tooltip.Content side="bottom" className="tooltip-content-class">
                            <Tooltip.Arrow className="tooltip-arrow-class"/>
                            {extraInformation}
                          </Tooltip.Content>
                        </Tooltip.Portal>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                    </span>:null
                    }
                </div>
                  {title?<p>{title}</p>:null}
              
              </li>
            )}  
          </ul>
        </div>
    </div>
  );
}

