import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router, Route, Switch, useLocation,Redirect } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import SamplePage from "./pages/SamplePage";
import Holiday from "./pages/Holiday";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import GiftCard from "./pages/GiftCard";
import ThriftState from "./pages/ThriftState";
import Partners from "./pages/Partners";
import SearchPage from "./pages/SearchPage";
import ThriftProud from "./pages/ThriftProud";
import Thriftorial from "./pages/Thriftorials";
import StoreLocator from "./pages/StoreLocator";
import StoreLocatorDetails from "./pages/StoreLocatorDetails";
import Rethink from "./pages/Rethink";
import Story from "./pages/Story";
import Header from "./components/Header";
import HeaderThrift from "./components/HeaderThrift";
import Footer from "./components/Footer";
import DonationPage from "./pages/DonationPage";
import ContactPage from "./pages/ContactPage";
import PageNotFound from "./components/PageNotFound";
import DynamicPage from "./pages/DynamicPage";
import DynamicPage2 from "./pages/DynamicPage2";
import * as serviceWorker from "./serviceWorker";
import SitemapPage from "./pages/SitemapPage";
import UrlGeneratorPage from './pages/UrlGeneratorPage';
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";
import "./i18n";
import $ from "jquery";
import { AppRoute, AppLanguage } from './const';
import { LocalizedRouter, LocalizedSwitch, appStrings } from"./modules/i18n";
import client from './Client';
import { getGlobalData } from "./api/cms/api";
import { getLocale } from "./api/cms/api";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const Root: React.FC<any> = ({ store }) => {

  const [thriftHeader, setThriftHeader] = useState(false);
  const [headerEntryId, setHeaderEntryId] = useState('');
  const [showFooter, setShowFooter] = useState(false);

  const location = window.location.pathname;
  let pathArray = location.split('/');
  const pattern = /[a-z]/;
  let localized_switch = false;
  let currPathAlias = '';
  if (pathArray[1] !== undefined && pathArray[1].match(pattern) && pathArray[1].length < 3) {
    localized_switch = true;

    pathArray = pathArray.filter(function (el) {
      return el != "";
    });
    pathArray.shift();
    currPathAlias = "/"+pathArray.join('/');
  }
  else {
    pathArray = pathArray.filter(function (el) {
      return el != "";
    });
    currPathAlias = "/"+pathArray.join('/');
  }

  const globalData = getGlobalData();
  const currLocale = getLocale();

  let hostname = window.location.hostname;
  const charlist = "/";
  hostname = hostname.replace(new RegExp("[" + charlist + "]+$"), "");

  let currPath = location.replace(new RegExp("^[" + charlist + "]+"), "");
  currPath = currPath.replace(new RegExp("[" + charlist + "]+$"), "");

    useEffect(() => {

      window.addEventListener("load", function(){
        setShowFooter(true)
      });

    function setRoutes()
    {
      if (!hostname.startsWith("www"))
      { 
        if(hostname == "savers.com" || hostname == "valuevillage.com" || hostname == "valuevillage.ca" || hostname == "villagedesvaleurs.com" || hostname == "villagedesvaleurs.ca" || hostname == "2ndavestores.com" || hostname == "savers.com.au")
        {
          window.location.href = window.location.protocol + "//" + "www." + window.location.host +"/"+currPath;  
        }
        else if (hostname == "valuevillagecom-react-test.metaltoad-sites.com") {
          if(currPath=="flexpointstest")
          {
            window.location.href = "http://valuevillageca-react-test.metaltoad-sites.com/super-savers/terms-caflexpointstest";
          }            
        }
        else if (hostname == "valuevillagecom-react-dev.metaltoad-sites.com") {
          if(currPath=="flexpointstest")
          {
            window.location.href = "http://valuevillageca-react-dev.metaltoad-sites.com/super-savers/terms-caflexpointstest";
          }            
        } 
      }
      else
      {
        if (hostname == "www.villagedesvaleurs.com") {
          window.location.href = "https://www.villagedesvaleurs.ca/"+currPath;
        }
        else if (hostname == "www.villagedesvaleurs.ca") {
          if(currPath=="carrieres")
          {
            window.location.href = "https://careers.valuevillage.ca/fr-CA";
          }
        }
        else if (hostname == "www.savers.com") {
          if(currPath=="careers")
          {
            window.location.href = "https://careers.savers.com";
          }
          else if(currPath=="fundrive")
          {
             window.location.href = "https://fundrive.savers.com";
          }         
        }
        else if (hostname == "www.2ndavestores.com") {
          if(currPath=="careers")
          {
            window.location.href = "https://careers.savers.com";
          }
          else if(currPath=="/oxford-valley-store-now-hiring")
          {
             window.location.href =  "https://careers.savers.com";
          }
          else if(currPath=="/north-wales-store-now-hiring")
          {
             window.location.href =  "https://careers.savers.com";
          }  
          else if(currPath=="/hamilton-store-now-hiring")
          {
             window.location.href =  "https://careers.savers.com";
          }
          else if(currPath=="/store-locations")
          {
             window.location.href =  "https://stores.savers.com/?site=2A&lang=EN&country=US";
          }       
          else if(currPath=="/store/")
          {
             window.location.href =  "https://stores.savers.com/?site=2A&lang=EN&country=US";
          }  
        }
        else if (hostname == "www.valuevillage.com") {
          if(currPath=="careers")
          {
            window.location.href = "https://careers.savers.com";
          }
          else if(currPath=="fundrive")
          {
             window.location.href = "https://fundrive.savers.com";
          }
          else if(currPath=="flexpointstest")
          {
            window.location.href = "https://www.valuevillage.ca/super-savers/terms-caflexpointstest";
           }            
        }
        else if (hostname == "www.valuevillage.ca") {
          if(currPath=="careers")
          {
            window.location.href = "https://careers.valuevillage.ca";
          }
          else if(currPath=="fundrive")
          {
             window.location.href = "https://fundrive.savers.com";
          }         
        }
      }
    }

    setRoutes();
    
    client.getEntries({
      content_type: 'header',
      'fields.domains': globalData.domain,
      'locale': currLocale,
      'limit': 1,
      'fields.aliasesToRenderHeader': currPathAlias,
    })
    .then(async (response: any) => {
      if (response.total > 0) {
        setHeaderEntryId(response.items[0].sys.id);
        if (response.items[0].fields.thriftHeader !== undefined && response.items[0].fields.thriftHeader === true) {
          setThriftHeader(true);
        }
      }
      else {

        let entryNotFound = true;
        let loop = true;

        while (entryNotFound && loop) {
        
          let currPathAliasArr = currPathAlias.split('/');
          currPathAliasArr.pop();

          currPathAlias = currPathAliasArr.join('/');
          let queryAlias = currPathAlias+"/*";

          if (queryAlias == "/*") {
            loop = false;
          }

          await client.getEntries({
            content_type: 'header',
            'fields.domains': globalData.domain,
            'locale': currLocale,
            'limit': 1,
            'fields.aliasesToRenderHeader': queryAlias,
          })
          .then(async (response: any) => {
            if (response.total > 0) {
              entryNotFound = false;
              setHeaderEntryId(response.items[0].sys.id);
              if (response.items[0].fields.thriftHeader !== undefined && response.items[0].fields.thriftHeader === true) {
                setThriftHeader(true);
              }
            }
          }).catch(console.error);
        }

        if (entryNotFound) {
          await client.getEntries({
            content_type: 'header',
            'fields.domains': globalData.domain,
            'fields.thriftHeader[ne]': 'yes',
            'fields.aliasesToRenderHeader[exists]': false,
            'locale': currLocale,
            'limit': 1,
          })
          .then((response: any) => {
            setHeaderEntryId(response.items[0].sys.id);
          })
          .catch(console.error);
        }
      }
    }).catch(console.error);
  }, []);

  return (
    <>
      <Provider store={store}>
        {localized_switch ?
          <LocalizedRouter
            RouterComponent={Router}
            languages={AppLanguage}
            appStrings={appStrings}
          >
            <LocalizedSwitch>
              {thriftHeader 
                ? <>
                    <Route path="/*" exact render={() => (<HeaderThrift headerEntryId={headerEntryId} />)} />
                    <Route path="/" exact render={() => (<HeaderThrift headerEntryId={headerEntryId} />)} />
                  </>
                : 
                  <>
                    <Route path="/*" exact render={() => (<Header headerEntryId={headerEntryId} />)} />
                    <Route path="/" exact render={() => (<Header headerEntryId={headerEntryId} />)} />
                  </>
              }
            </LocalizedSwitch>
            <LocalizedSwitch>
              <Route exact path="/faq/there-super-savers-club™-test-program-happening-my-area-0">  
               <Redirect to="/super-savers/" />
              </Route> 
              <Route exact path="/faq/i’ve-moved-how-do-i-change-my-address">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/avantages">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/parcours-de-carriere">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/formation">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/services">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/culture">  
               <Redirect to="/a-propos/" />
              </Route> 
              <Route exact path="/faq/comment-acheter-une-carte-cadeau">  
               <Redirect to="/club-des-super-aubaines/" />
              </Route> 
              <Route exact path="/faq/puis-je-acheter-une-carte-cadeau-en-ligne">  
               <Redirect to="/club-des-super-aubaines/" />
              </Route>
              <Route exact path="/conditions-cartes-cadeaux">  
               <Redirect to="/cartes-cadeaux/conditions-utilisation/" />
              </Route> 
              <Route exact path="/conditions-CAtestpourcentage">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/aide">  
               <Redirect to="/nous-joindre/" />
              </Route>              
              <Route exact path="/impact/partenaires-sans-but-lucratif">  
               <Redirect to="/fiers-de-reutiliser/impact/" />
              </Route> 
              <Route exact path="/direction">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/politique-de-condidentialite">
               <Redirect to="/politique-de-confidentialite/" />
              </Route> 
              <Route exact path="/inspiration/mode-ecologique">  
               <Redirect to="/fiers-de-reutiliser/reutilisons" />
              </Route> 
              <Route exact path="/dons/a-quoi-servent-vos-dons">  
               <Redirect to="/dons/" />
              </Route> 
              <Route exact path="/reutilisons">  
               <Redirect to="/temps-des-fetes/" />
              </Route> 
              <Route exact path="/inspiration/tempsdesfetes">  
               <Redirect to="/temps-des-fetes/" />
              </Route> 
              <Route exact path="/a-propos-de-nous">  
               <Redirect to="/a-propos/" />
              </Route> 
              <Route exact path="/magasinage">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/magasinage/cartes-cadeaux">  
               <Redirect to="/cartes-cadeaux/" />
              </Route> 
              <Route exact path="/conditions-csa">  
               <Redirect to="/" />
              </Route>  
              <Route exact path="/soldessecuritaires">  
               <Redirect to="/soldes-securitaires/" />
              </Route>
              <Route exact path="/dons/ce-que-nous-acceptons">  
               <Redirect to="/dons" />
              </Route>
              <Route exact path="/faq/how-do-i-replace-missing-club-card">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/i-lost-my-gift-card-can-i-get-replacement">  
               <Redirect to="gift-cards" />
              </Route>
              <Route exact path="/careers/training">  
               <Redirect to="/careers" />
              </Route>
             
              <Route exact path="/faq/gift-card-thrift-store-really-good-gift">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/what-exactly-club-card-anyway">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/careers/career-path">  
               <Redirect to="/careers" />
              </Route>
              <Route exact path="/faq/how-do-i-purchase-gift-card-0">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/careers/departments">  
               <Redirect to="/careers/" />
              </Route>
              <Route exact path="/careers/benefits">  
               <Redirect to="/careers/" />
              </Route>
              <Route exact path="/faq/can-i-buy-gift-card-online-0">  
               <Redirect to="/gift-cards" />
              </Route> 
              
              <Route exact path="/faq/how-do-i-get-club-card">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/impact">  
               <Redirect to="/thrift-proud/impact/" />
              </Route>
              <Route exact path="/terms-of-use/club-card-terms">  
               <Redirect to="/super-savers/program-terms" />
              </Route>
              <Route exact path="/careers/training">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/terms-CAflexpointstest">  
               <Redirect to="/flexpointtest/" />
              </Route>
              <Route exact path="/program-terms-CA">  
               <Redirect to="/super-savers/program-terms-ca/" />
              </Route>
              
              <Route exact path="/faq/how-do-i-update-my-account-information-0">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-redeem-email-coupon">  
               <Redirect to="/" />
              </Route>
              
              <Route exact path="/SSCCanada">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/program-terms-US">  
               <Redirect to="/super-savers/program-terms/" />
              </Route>
              <Route exact path="/faq/how-do-i-purchase-gift-card">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/how-do-i-get-my-birthday-discount">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/terms-CApercenttest">  
               <Redirect to="/super-savers/program-terms-ca/" />
              </Route> 
              
              <Route exact path="/faq/what-are-you-doing-support-ontarians-disabilities-act-aoda">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/it-free-join-0">  
               <Redirect to="/" />
              </Route>
              
              <Route exact path="/donate/how-your-donations-help">
                <Redirect to="/donate" />
              </Route>
              
              <Route exact path="/ssc">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/our-culture">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/career-path">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/departments">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/benefits">  
               <Redirect to="/" />
              </Route>
             <Route exact path="/shop/gift-cards">
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/donate/*">
               <Redirect to="/donate" />
              </Route>
              <Route exact path="/impact/savers-partners">
               <Redirect to="/partners" />
              </Route>
              <Route exact path="/help">
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration/eco-fashion"> 
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration/home"> 
               <Redirect to="/thrift-proud/style" />
              </Route>
               <Route exact path="/impact"> 
               <Redirect to="/thrift-proud/impact/" />
              </Route>
              <Route exact path="/safesales"> 
               <Redirect to="/safe-sales" />
              </Route>
              <Route exact path="/inspiration/celebrations"> 
               <Redirect to="/thrift-proud/style" />
              </Route>
              <Route exact path="/shop"> 
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration/style"> 
               <Redirect to="/thrift-proud/style/" />
              </Route>
              <Route exact path="/shop/thrift-tips"> 
               <Redirect to="/thrift-proud/thriftorials/" />
              </Route>
              <Route exact path="/program-terms"> 
               <Redirect to="/super-savers/program-terms/" />
              </Route>
              <Route exact path="/shop/grand-openings"> 
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration"> 
               <Redirect to="/thrift-proud/style/" />
              </Route>
              <Route exact path="/inspiration/holiday">  
               <Redirect to="/thrift-proud/style/" />
              </Route>
              <Route exact path="/rethinkreuse">  
               <Redirect to="/thrift-proud/rethink-reuse/" />
              </Route>
              <Route exact path="/faq/how-do-i-join-super-savers-club®">  
               <Redirect to="/super-savers/program-terms/" />
              </Route>
              <Route exact path="/faq/how-do-i-purchase-gift-card">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/how-do-i-redeem-email-coupon">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/i-want-set-direct-deposit-how-do-i-do">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/i-have-lost-my-gift-card-can-i-get-replacement">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/what-types-items-do-you-accept-and-not-accept">  
               <Redirect to="/donate" />
              </Route>
              <Route exact path="/faq/it-free-join">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/i-have-question-about-gift-card-i-ordered-online-who-do-i-contact">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/terms-of-use/giftcardterms">  
               <Redirect to="/gift-cards/terms-of-use/" />
              </Route>
              <Route exact path="/faq/how-do-i-update-my-personal-information">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-check-my-points-and-rewards-balance">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/help/contact">  
               <Redirect to="/contact-us" />
              </Route>
              <Route exact path="/faq/how-do-i-update-my-account-information">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/unique">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/can-i-download-digital-copy-my-w-2-or-t-4">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/do-club-members-need-card">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/faq/can-i-add-more-money-my-gift-card-1">  
               <Redirect to="/gift-cards/terms-of-use/" />
              </Route>               
              <Route exact path="/faq/does-my-gift-card-expire">  
               <Redirect to="/gift-cards/" />
              </Route>
              <Route exact path="/faq/what-exactly-super-savers-club®-anyway">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/faq/i-am-having-trouble-accessing-myhr-how-do-i-access-it">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/faq/what-do-i-do-if-my-card-doesnt-work">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/how-do-i-add-or-update-email-address-associated-my-super-savers-club-account">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/faq/i-still-have-questions-who-can-i-contact">  
               <Redirect to="/contact-us/" />
              </Route>
              <Route exact path="/faq/im-looking-specific-item-can-you-help-me-find-it">  
               <Redirect to="/contact-us/" />
              </Route>
              <Route exact path="/faq/im-former-employee-and-id-join-team-again-how-do-i-reapply-0">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-get-my-birthday-discount-0">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-view-past-pay-stubs">  
               <Redirect to="/" />
              </Route>
              <Route exact path="faq/i’ve-moved-how-do-i-change-my-address">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-can-i-check-my-gift-card-balance-0">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/do-you-offer-corporate-gift-cards-or-bulk-purchase">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/inspiration/holiday">  
               <Redirect to="/" />
              </Route> 
              <Route path="/" exact component={HomePage} ></Route>
              <Route path="/sitemap-generator" exact component={SitemapPage} ></Route>
              <Route path="/url-generator" exact component={UrlGeneratorPage} ></Route>
              <Route path="/thrift-proud/style" exact component={ThriftProud} />
              <Route path="/store-locator" exact component={StoreLocator} />
              <Route path="/thriftorials/:path1" exact component={DynamicPage2} />
              <Route path="/store-locator-details" exact component={StoreLocatorDetails} />
              <Route path="/holiday/" exact component={Holiday} />
              <Route path="/donate/" exact component={DonationPage} />
              <Route path="/contact-us/" exact component={ContactPage} />
              <Route path="/about-us/" exact component={AboutPage} />
              <Route path="/gift-cards/" exact component={GiftCard} />
              <Route path="/partners/" exact component={Partners} />
              <Route path="/search/:keyword" exact component={SearchPage} />
              <Route path="/thrift-state" exact component={ThriftState} />
              <Route path="/:alias1" exact component={DynamicPage} />
              <Route path="/:alias1/:alias2" exact component={DynamicPage} />
              <Route path="/*" exact component={DynamicPage} />
            </LocalizedSwitch>
            <LocalizedSwitch>
              <Route path="/" exact component={Footer} />
              <Route path="/*" exact component={Footer} />
            </LocalizedSwitch>
          </LocalizedRouter>
        :
          <Router>
            <Switch>
              {thriftHeader 
                ? <>
                    <Route path="/*" exact render={() => (<HeaderThrift headerEntryId={headerEntryId} />)} />
                    <Route path="/" exact render={() => (<HeaderThrift headerEntryId={headerEntryId} />)} />
                  </>
                : 
                  <>
                    <Route path="/*" exact render={() => (<Header headerEntryId={headerEntryId} />)} />
                    {/*<Route path="/" exact render={() => (<Header headerEntryId={headerEntryId} />)} />*/}
                  </>
              }
            </Switch>
            <Switch>
              {/* START Added specifically for 2ndAve */}
              <Route exact path="/blog">  
               <Redirect to="/thrift-proud/thriftorials" />
              </Route>
              <Route exact path="/blog/*">  
               <Redirect to="/thrift-proud/thriftorials" />
              </Route> 
              <Route exact path="/blog/covid-19">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/sales-and-specials">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/loyalty-rewards-program">  
               <Redirect to="/loyalty-rewards" />
              </Route>
              <Route exact path="/loyalty-rewards-program/terms">  
               <Redirect to="/loyalty-rewards/program-terms" />
              </Route>
              <Route exact path="/loyalty-rewards-faq">  
               <Redirect to="/contact-us" />
              </Route>
              <Route exact path="/gift-cards/faq">  
               <Redirect to="/contact-us?contact_category=Gift_cards" />
              </Route>
              <Route exact path="/great-finds">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/recycling">  
               <Redirect to="/thrift-proud/rethink-reuse" />
              </Route>

              <Route exact path="/about-us/terms-of-use">  
               <Redirect to="/terms-of-use" />
              </Route>
              <Route exact path="/about-us/privacy-policy">  
               <Redirect to="/privacy-policy" />
              </Route>

              <Route exact path="/oxford-valley-store-now-hiring" component={() => {
                  window.location.href = "https://careers.savers.com";
                  return null;
              }}/>
              <Route exact path="/north-wales-store-now-hiring" component={() => {
                  window.location.href = "https://careers.savers.com";
                  return null;
              }}/>
              <Route exact path="/hamilton-store-now-hiring" component={() => {
                  window.location.href = "https://careers.savers.com";
                  return null;
              }}/>
              
              <Route exact path="/store-locations" component={() => {
                  window.location.href = "https://stores.savers.com/?site=2A&lang=EN&country=US";
                  return null;
              }}/>
              <Route exact path="/store/*" component={() => {
                  window.location.href = "https://stores.savers.com/?site=2A&lang=EN&country=US";
                  return null;
              }}/>


              {/* END Added specifically for 2ndAve */}
              <Route exact path="/faq/there-super-savers-club™-test-program-happening-my-area-0">  
               <Redirect to="/super-savers/" />
              </Route> 
              <Route exact path="/faq/i’ve-moved-how-do-i-change-my-address">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/avantages">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/parcours-de-carriere">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/formation">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/services">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/carrieres/culture">  
               <Redirect to="/a-propos/" />
              </Route> 
              <Route exact path="/faq/comment-acheter-une-carte-cadeau">  
               <Redirect to="/club-des-super-aubaines/" />
              </Route> 
              <Route exact path="/faq/puis-je-acheter-une-carte-cadeau-en-ligne">  
               <Redirect to="/club-des-super-aubaines/" />
              </Route>
              <Route exact path="/conditions-cartes-cadeaux">  
               <Redirect to="/cartes-cadeaux/conditions-utilisation/" />
              </Route> 
              <Route exact path="/conditions-CAtestpourcentage">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/aide">  
               <Redirect to="/nous-joindre/" />
              </Route> 
              <Route exact path="/impact/partenaires-sans-but-lucratif">  
               <Redirect to="/fiers-de-reutiliser/impact/" />
              </Route> 
              <Route exact path="/direction">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/politique-de-condidentialite">  
               <Redirect to="/politique-de-confidentialite/" />
              </Route>
              <Route exact path="/inspiration/mode-ecologique">  
               <Redirect to="/fiers-de-reutiliser/reutilisons" />
              </Route> 
              <Route exact path="/dons/a-quoi-servent-vos-dons">  
               <Redirect to="/dons/" />
              </Route> 
              <Route exact path="/reutilisons">  
               <Redirect to="/temps-des-fetes/" />
              </Route> 
              <Route exact path="/inspiration/tempsdesfetes">  
               <Redirect to="/temps-des-fetes/" />
              </Route> 
              <Route exact path="/a-propos-de-nous">  
               <Redirect to="/a-propos/" />
              </Route> 
              <Route exact path="/magasinage">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/magasinage/cartes-cadeaux">  
               <Redirect to="/cartes-cadeaux/" />
              </Route> 
              <Route exact path="/conditions-csa">  
               <Redirect to="/" />
              </Route>  
              <Route exact path="/soldessecuritaires">  
               <Redirect to="/soldes-securitaires/" />
              </Route>
              <Route exact path="/dons/ce-que-nous-acceptons">  
               <Redirect to="/dons" />
              </Route>
              <Route exact path="/faq/how-do-i-replace-missing-club-card">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/i-lost-my-gift-card-can-i-get-replacement">  
               <Redirect to="gift-cards" />
              </Route>
              <Route exact path="/careers/training">  
               <Redirect to="/careers" />
              </Route>
             
              <Route exact path="/faq/gift-card-thrift-store-really-good-gift">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/what-exactly-club-card-anyway">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/careers/career-path">  
               <Redirect to="/careers" />
              </Route>
              <Route exact path="/faq/how-do-i-purchase-gift-card-0">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/careers/departments">  
               <Redirect to="/careers/" />
              </Route>
              <Route exact path="/careers/benefits">  
               <Redirect to="/careers/" />
              </Route>
              <Route exact path="/faq/can-i-buy-gift-card-online-0">  
               <Redirect to="/gift-cards" />
              </Route> 
              
              <Route exact path="/faq/how-do-i-get-club-card">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/impact">  
               <Redirect to="/thrift-proud/impact/" />
              </Route>
              <Route exact path="/terms-of-use/club-card-terms">  
               <Redirect to="/super-savers/program-terms" />
              </Route>
              <Route exact path="/careers/training">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/terms-CAflexpointstest">  
               <Redirect to="/flexpointtest/" />
              </Route>
              <Route exact path="/program-terms-CA">  
               <Redirect to="/super-savers/program-terms-ca/" />
              </Route>
              
              <Route exact path="/faq/how-do-i-update-my-account-information-0">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-redeem-email-coupon">  
               <Redirect to="/" />
              </Route>
              
              <Route exact path="/SSCCanada">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/program-terms-US">  
               <Redirect to="/super-savers/program-terms/" />
              </Route>
              <Route exact path="/faq/how-do-i-purchase-gift-card">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/how-do-i-get-my-birthday-discount">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/terms-CApercenttest">  
               <Redirect to="/super-savers/program-terms-ca/" />
              </Route> 
              
              <Route exact path="/faq/what-are-you-doing-support-ontarians-disabilities-act-aoda">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/it-free-join-0">  
               <Redirect to="/" />
              </Route>
              
              <Route exact path="/donate/how-your-donations-help">
                <Redirect to="/donate" />
              </Route>
              
              <Route exact path="/ssc">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/our-culture">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/career-path">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/departments">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/careers/benefits">  
               <Redirect to="/" />
              </Route>
             <Route exact path="/shop/gift-cards">
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/donate/*">
               <Redirect to="/donate" />
              </Route>
              <Route exact path="/impact/savers-partners">
               <Redirect to="/partners" />
              </Route>
              <Route exact path="/help">
               <Redirect to="/contact-us" />
              </Route>
              <Route exact path="/inspiration/eco-fashion"> 
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration/home"> 
               <Redirect to="/thrift-proud/style" />
              </Route>
               <Route exact path="/impact"> 
               <Redirect to="/thrift-proud/impact/" />
              </Route>
              <Route exact path="/safesales"> 
               <Redirect to="/safe-sales" />
              </Route>
              <Route exact path="/inspiration/celebrations"> 
               <Redirect to="/thrift-proud/style" />
              </Route>
              <Route exact path="/shop"> 
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration/style"> 
               <Redirect to="/thrift-proud/style/" />
              </Route>
              <Route exact path="/shop/thrift-tips"> 
               <Redirect to="/thrift-proud/thriftorials/" />
              </Route>
              <Route exact path="/program-terms"> 
               <Redirect to="/super-savers/program-terms/" />
              </Route>
              <Route exact path="/shop/grand-openings"> 
               <Redirect to="/" />
              </Route>
              <Route exact path="/inspiration"> 
               <Redirect to="/thrift-proud/style/" />
              </Route>
              <Route exact path="/inspiration/holiday">  
               <Redirect to="/thrift-proud/style/" />
              </Route>
              <Route exact path="/rethinkreuse">  
               <Redirect to="/thrift-proud/rethink-reuse/" />
              </Route>
              <Route exact path="/faq/how-do-i-join-super-savers-club®">  
               <Redirect to="/super-savers/program-terms/" />
              </Route>
              <Route exact path="/faq/how-do-i-purchase-gift-card">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/how-do-i-redeem-email-coupon">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/i-want-set-direct-deposit-how-do-i-do">  
               <Redirect to="/super-savers" />
              </Route>
              <Route exact path="/faq/i-have-lost-my-gift-card-can-i-get-replacement">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/what-types-items-do-you-accept-and-not-accept">  
               <Redirect to="/donate" />
              </Route>
              <Route exact path="/faq/it-free-join">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/i-have-question-about-gift-card-i-ordered-online-who-do-i-contact">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/terms-of-use/giftcardterms">  
               <Redirect to="/gift-cards/terms-of-use/" />
              </Route>
              <Route exact path="/faq/how-do-i-update-my-personal-information">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-check-my-points-and-rewards-balance">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/help/contact">  
               <Redirect to="/contact-us" />
              </Route>
              <Route exact path="/faq/how-do-i-update-my-account-information">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/unique">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/can-i-download-digital-copy-my-w-2-or-t-4">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/do-club-members-need-card">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/faq/can-i-add-more-money-my-gift-card-1">  
               <Redirect to="/gift-cards/terms-of-use/" />
              </Route>               
              <Route exact path="/faq/does-my-gift-card-expire">  
               <Redirect to="/gift-cards/" />
              </Route>
              <Route exact path="/faq/what-exactly-super-savers-club®-anyway">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/faq/i-am-having-trouble-accessing-myhr-how-do-i-access-it">  
               <Redirect to="/" />
              </Route> 
              <Route exact path="/faq/what-do-i-do-if-my-card-doesnt-work">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/how-do-i-add-or-update-email-address-associated-my-super-savers-club-account">  
               <Redirect to="/super-savers/" />
              </Route>
              <Route exact path="/faq/i-still-have-questions-who-can-i-contact">  
               <Redirect to="/contact-us/" />
              </Route>
              <Route exact path="/faq/im-looking-specific-item-can-you-help-me-find-it">  
               <Redirect to="/contact-us/" />
              </Route>
              <Route exact path="/faq/im-former-employee-and-id-join-team-again-how-do-i-reapply-0">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-get-my-birthday-discount-0">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-do-i-view-past-pay-stubs">  
               <Redirect to="/" />
              </Route>
              <Route exact path="faq/i’ve-moved-how-do-i-change-my-address">  
               <Redirect to="/" />
              </Route>
              <Route exact path="/faq/how-can-i-check-my-gift-card-balance-0">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/faq/do-you-offer-corporate-gift-cards-or-bulk-purchase">  
               <Redirect to="/gift-cards" />
              </Route>
              <Route exact path="/inspiration/holiday">  
               <Redirect to="/" />
              </Route>
              <Route path="/" exact component={HomePage} ></Route>
              <Route path="/sitemap-generator" exact component={SitemapPage} ></Route>
              <Route path="/url-generator" exact component={UrlGeneratorPage} ></Route>
              <Route path="/thrift-proud/style" exact  component={ThriftProud} />
              <Route path="/store-locator" exact component={StoreLocator} />
              <Route path="/thriftorials/:path1" exact component={DynamicPage2} />
              <Route path="/store-locator-details" exact component={StoreLocatorDetails} />
              <Route path="/holiday/" exact component={Holiday} />
              <Route path="/donate/" exact component={DonationPage} />
              <Route path="/contact-us/" exact component={ContactPage} />
              <Route path="/about-us/" exact component={AboutPage} />
              <Route path="/gift-cards/" exact component={GiftCard} />
              <Route path="/partners/" exact component={Partners} />
              <Route path="/search/:keyword" exact component={SearchPage} />
              <Route path="/thrift-state" exact component={ThriftState} />
              <Route path="/:alias1" exact component={DynamicPage} />
              <Route path="/:alias1/:alias2" exact component={DynamicPage} />
              <Route path="/*" exact component={DynamicPage} />
            </Switch>
            <Switch>
              <Route path="/" exact component={()=>showFooter === true ? <Footer /> : null}/>
              <Route path="/*" exact component={()=>showFooter === true ? <Footer /> : null} />
            </Switch>
          </Router>
        }
      </Provider>
    </>
  );
}

ReactDOM.render(<Root store={store} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
