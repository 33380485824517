import React, { useEffect, useState, Fragment } from "react";
import { connect } from 'react-redux';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from "../../Client";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getGlobalData } from "../../api/cms/api";
import { Redirect,Route } from "react-router-dom";
import $ from 'jquery';
import Cookies from "universal-cookie";

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import validator from "validator";
import { css } from "@emotion/core";
/** @jsx jsx */
import { jsx } from '@emotion/core';

declare global {
  interface Window {
    recaptchaCallback:any;
  }
}

const cookies = new Cookies();

const ContactUsForm: React.FC<any> = (componentData) => {
  // #region STATES
  const [emailError, setEmailError] = useState('');
  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [formLabel, setFormLabel] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [nameLabel, setNameLabel] = useState("");
  const [emailLabel, setEmailLabel] = useState("");
  const [emailValidation, setEmailValidation] = useState("");
  const [phoneLabel, setPhoneLabel] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");
  const [subjectLabel, setSubjectLabel] = useState("");
  const [descriptionLabel, setDescriptionLabel] = useState("");
  const [brandLabel, setBrandLabel] = useState("");
  const [countryLabel, setCountryLabel] = useState("");
  const [submitLabel, setSubmitLabel] = useState("");
  const [hideFields,setHideFields]= useState(true);
  const [postalZipLabel, setPostalZipLabel] = useState("");
  const [locationLabel, setLocationLabel] = useState("");
  const [noteLabel, setNoteLabel] = useState(""); //
  const [nameRequired, setNameRequired] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [categoryRequired, setCategoryRequired] = useState(false);
  const [subjectRequired, setSubjectRequired] = useState(false);
  const [postalZipRequired, setPostalZipRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false); 
  const [descriptionRequired, setDescriptionRequired] = useState(false); 
  const [noteRequired, setNoteRequired] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [country, setCountry] = useState("");
  const [postalZip, setPostalZip] = useState("");
  const [backgroundColor,setBackgroundColor]=useState("");
  const [location,setLocation]=useState('');
  const [locationData,setLocationData] = useState<any>([]);
  const [storeNumber,setStoreNumber] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [locationErrorMessage, setLocationErrorMessage] = useState("");
  const [postalZipErrorMessage, setPostalZipErrorMessage] = useState("");
  const [nameErrorMessageVisible, setNameErrorMessageVisible] = useState(false);
  const [emailErrorMessageVisible, setEmailErrorMessageVisible] = useState(false);
  const [phoneErrorMessageVisible, setPhoneErrorMessageVisible] = useState(false);
  const [categoryErrorMessageVisible, setCategoryErrorMessageVisible] = useState(false);
  const [subjectErrorMessageVisible, setSubjectErrorMessageVisible] = useState(false);
  const [descriptionErrorMessageVisible, setDescriptionErrorMessageVisible] = useState(false);
  const [locationErrorMessageVisible, setLocationErrorMessageVisible] = useState(false);
  const [postalZipErrorMessageVisible, setPostalZipErrorMessageVisible] = useState(false);
  const [redirectUrl,setRedirectUrl]=useState('');
  const [topicsList,setTopicsList]=useState([]);
  const [api,setApi]= useState("");
  const [myDescription,setMyDescription]= useState("");
  const [phoneSendingData,setPhoneSendingData]= useState("");
  const [zipcodeSendingData,setZipcodeSendingData]= useState("");
  const [locationSendingData,setLocationSendingData]= useState("");
  const [storeNumSendingData,setStoreNumSendingData]= useState("");
  const [descriptionSendingData,setDescriptionSendingData]= useState("");
  const [selectedTopicSendingData,setSelectedTopicSendingData]= useState("");
  const [subjectSendingData,setSubjectSendingData]= useState("");
  const [backgroundImageDesktop,setBackgroundImageDesktop] = useState('');
  const [backgroundImageMobile,setBackgroundImageMobile] = useState('');
  const [disclaimerText,setDisclaimerText] = useState('');
  const [captchaSiteKey, setCaptchaSiteKey] = useState('');
  const [timerID, setTimerID] = useState<any>(null);
  const [locationOptionsLoading, setLocationOptionsLoading] = useState(true);
  const [submitButtonBackgroundColor, setSubmitButtonBackgroundColor] = useState("");
  const [submitButtonHoverColor, setSubmitButtonHoverColor] = useState("");

  const [clickedSubmit, setClickedSubmit] = useState(false);
  //#endregion STATES

  const recaptchaCallback = () => {
    setRecaptchaChecked(true);
    console.log("RECAPTCHA CHECKED!")
  } 

  let langToggleInterval = setInterval(function(){
      if($(".g-recaptcha").length > 0){       
         $("#g-recaptcha-response").attr("aria-label","recapche");
         $("#g-recaptcha-response").attr("aria-required","true");
      }
  }, 300);

  setTimeout(() => {
    clearInterval(langToggleInterval);
  }, 3000);

  useEffect(() => {
    const globalData = getGlobalData();
    const storeSite = globalData.storeSite;
    const countryCode = globalData.countryCode;
    setApi(globalData.locationApi)

    $("#hideField").css("display", "none");
    $("#hideDescription").css("display", "none");

    window.recaptchaCallback = recaptchaCallback;

    if(storeSite!="" && storeSite!=undefined)
    {
       setBrand(storeSite);
    }

    if(storeSite=="" && storeSite==undefined)
    {
       setBrand("SPAM");
    }

    if(countryCode!="" && countryCode!=undefined)
    {
      
       if(countryCode=="US")
       {
          setCountry("United States");
       }
       else if(countryCode=="AU")
       {
          setCountry("Australia");
       }
       else if(countryCode=="CA")
       {
          setCountry("Canada");
       }
       else
       {
         setCountry("UNKNOWN")
       }

    }

    if (componentData.formLabel != undefined && componentData.formLabel != "") {
      setFormLabel(componentData.formLabel);
    }

    if (componentData.redirectUrl != undefined && componentData.redirectUrl != "") {
      if(componentData.redirectUrl.includes('http')||componentData.redirectUrl.includes('https'))
      {
        setRedirectUrl(componentData.redirectUrl);
      }
      else{
        let hostname=window.location.hostname;
        if(hostname!=""&& hostname!=undefined)
        {
          setRedirectUrl(hostname+componentData.redirectUrl);
        }
      }
      
    }

    if (componentData.subTitle != undefined && componentData.subTitle != "") {
      setSubTitle(componentData.subTitle);
    }

    if (componentData.postalZipLabel != undefined && componentData.postalZipLabel != "") {
      setPostalZipLabel(componentData.postalZipLabel);
    }

    if (componentData.postalZipRequired != undefined && componentData.postalZipRequired != "") {
      setPostalZipRequired(componentData.postalZipRequired);
    }

    if (componentData.nameLabel != undefined && componentData.nameLabel != "") {
      setNameLabel(componentData.nameLabel);
    }
    if (componentData.nameRequired != undefined && componentData.nameRequired != "") {
      setNameRequired(componentData.nameRequired);
    }

    if (componentData.emailLabel != undefined && componentData.emailLabel != "") {
      setEmailLabel(componentData.emailLabel);
    }

    if (componentData.emailValidationMessage != undefined && componentData.emailValidationMessage != "") {
      setEmailValidation(componentData.emailValidationMessage);
    }

    if (componentData.emailRequired != undefined && componentData.emailRequired != "") {
      setEmailRequired(componentData.emailRequired);
    }

    if (componentData.phoneLabel != undefined && componentData.phoneLabel != "") {
      setPhoneLabel(componentData.phoneLabel);
    }

    if (componentData.phoneRequired != undefined && componentData.phoneRequired != "") {
      setPhoneRequired(componentData.phoneRequired);
    }

    if (componentData.categoryLabel != undefined && componentData.categoryLabel != "") {
      setCategoryLabel(componentData.categoryLabel);
    }

    if (componentData.categoryRequired != undefined && componentData.categoryRequired != "") {
      setCategoryRequired(componentData.categoryRequired);
    }

    if (componentData.topicsList != undefined && componentData.topicsList != "") {
      setTopicsList(componentData.topicsList);
    }

    if (componentData.subjectLabel != undefined && componentData.subjectLabel != "") {
      setSubjectLabel(componentData.subjectLabel);
    }

    if (componentData.subjectRequired != undefined && componentData.subjectRequired != "") {
      setSubjectRequired(componentData.subjectRequired);
    }
    
    if (componentData.descriptionLabel != undefined && componentData.descriptionLabel != "") {
      setDescriptionLabel(componentData.descriptionLabel);
    }

    if (componentData.descriptionRequired != undefined && componentData.descriptionRequired != "") {
      setDescriptionRequired(componentData.descriptionRequired);
    }
    
    if (componentData.brandLabel != undefined && componentData.brandLabel != "") {
      setBrandLabel(componentData.brandLabel);
    }
    
    if (componentData.countryLabel != undefined && componentData.countryLabel != "") {
      setCountryLabel(componentData.countryLabel);
    }
    
    if (componentData.submitLabel != undefined && componentData.submitLabel != "") {
      setSubmitLabel(componentData.submitLabel);
    }

    if (componentData.backgroundColor != undefined && componentData.backgroundColor != "") {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.locationLabel != undefined && componentData.locationLabel != "") {
      setLocationLabel(componentData.locationLabel);
    }

    if (componentData.locationRequired != undefined && componentData.locationRequired != "") {
      setLocationRequired(componentData.locationRequired);
    }

    if (componentData.noteLabel != undefined && componentData.noteLabel != "") {
      setNoteLabel(componentData.noteLabel);
    }

    if (componentData.noteRequired != undefined && componentData.noteRequired != "") {
      setNoteRequired(componentData.noteRequired);
    }

     if (componentData.nameErrorMessage != undefined && componentData.nameErrorMessage != "") {
      setNameErrorMessage(componentData.nameErrorMessage);
      if(componentData.nameRequired==true)
      {
        var message:any = document.getElementById("name");
        if(message!=null)
        {
          message.oninvalid = function(e:any) {
            if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
            {
              e.target.setCustomValidity(componentData.nameErrorMessage);
            }   
            else
            {
              e.target.setCustomValidity("");
            }  

          };
        }
        
      }
      
    }

    if (componentData.emailErrorMessage != undefined && componentData.emailErrorMessage != "") {
      setEmailErrorMessage(componentData.emailErrorMessage);
      if(componentData.emailRequired==true)
      {
        var message:any = document.getElementById("email");
         if(message!=null){
          message.oninvalid = function(e:any) {
             if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
            {
              e.target.setCustomValidity(componentData.emailErrorMessage);
            }
             else
            {
              e.target.setCustomValidity("");
            }
          };
         }
        
      }
      
    }

    if (componentData.phoneErrorMessage != undefined && componentData.phoneErrorMessage != "") {
      setPhoneErrorMessage(componentData.phoneErrorMessage);
      if(componentData.phoneRequired==true)
      {
        var message:any = document.getElementById("phone");
        if(message!=null){
          
          message.oninvalid = function(e:any) {
           if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
            { 
               e.target.setCustomValidity(componentData.phoneErrorMessage);
            }
             else
            {
                 e.target.setCustomValidity("");
            }
          };
        }
        
      }
      
    }

    if (componentData.categoryErrorMessage != undefined && componentData.categoryErrorMessage != "") {
      setCategoryErrorMessage(componentData.categoryErrorMessage);
      if(componentData.categoryRequired==true)
      {
        var message:any = document.getElementById("00N3i00000CKu6N");
        if(message!=null)
        {
            message.oninvalid = function(e:any) {
            if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
            {
              e.target.setCustomValidity(componentData.categoryErrorMessage);
            }
             else
            {
                 e.target.setCustomValidity("");
            }
           
          };
        }
      }
      
    }

    if (componentData.postalZipErrorMessage != undefined && componentData.postalZipErrorMessage != "") {
      setPostalZipErrorMessage(componentData.postalZipErrorMessage);
      if(componentData.postalZipRequired==true)
      {
        var message:any = document.getElementById("postalZip");
        if(message!=null)
        {
          message.oninvalid = function(e:any) {
            if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
            {
              e.target.setCustomValidity(componentData.postalZipErrorMessage);
            }
             else
            {
                 e.target.setCustomValidity("");
            }

         };
        }
      }
      
        
    }

    if (componentData.locationErrorMessage != undefined && componentData.locationErrorMessage != "") {
      setLocationErrorMessage(componentData.locationErrorMessage);
      if(componentData.locationRequired==true)
      {
        var message:any = document.getElementById("location");
        if(message!=null)
        {
          message.oninvalid = function(e:any) {
          if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
          {
           e.target.setCustomValidity(componentData.locationErrorMessage);
         }
          else
            {
                 e.target.setCustomValidity("");
            }
         };
        }
      }
      
        
    }

     if (componentData.subjectErrorMessage != undefined && componentData.subjectErrorMessage != "") {
      setSubjectErrorMessage(componentData.subjectErrorMessage);
      if(componentData.subjectRequired==true)
      {
         var message:any = document.getElementById("subject");
         if(message!=null)
         {
           message.oninvalid = function(e:any) {
          if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
          {
           e.target.setCustomValidity(componentData.subjectErrorMessage);
          }
          else
           {
                e.target.setCustomValidity("");
           }
          };
         }
      }
     
    }

     if (componentData.descriptionErrorMessage != undefined && componentData.descriptionErrorMessage != "") {
      setDescriptionErrorMessage(componentData.descriptionErrorMessage);
      if(componentData.descriptionRequired==true)
      {
         var message:any = document.getElementById("mydescription");
         if(message!="")
         {
           message.oninvalid = function(e:any) {
          if(e.target.value=="" || e.target.value==undefined ||e.target.value==null)
          {
           e.target.setCustomValidity(componentData.descriptionErrorMessage);
          }
           else
            {
                 e.target.setCustomValidity("");
            }
          };
         }
      } 
        
    }

    if(componentData.backgroundImage != undefined && componentData.backgroundImage != "")
    {
      let imagePath = "";
      imagePath = componentData.backgroundImage.fields.file.url;
      setBackgroundImageDesktop(imagePath);
    }

    if(componentData.mobileBackgroundImage != undefined && componentData.mobileBackgroundImage != "")
    {
      let imagePath = "";
      imagePath = componentData.mobileBackgroundImage.fields.file.url;
      setBackgroundImageMobile(imagePath);
    }

    if(componentData.disclaimerText != undefined && componentData.disclaimerText != "")
    {
        setDisclaimerText(componentData.disclaimerText);
    }

    if(componentData.captchaSiteKey !== undefined && componentData.captchaSiteKey !== "") {
      setCaptchaSiteKey(componentData.captchaSiteKey);
    }

    if(componentData.submitButtonBackgroundColor !== undefined && componentData.submitButtonBackgroundColor !== "") {
      setSubmitButtonBackgroundColor(componentData.submitButtonBackgroundColor);
    }

    if(componentData.submitButtonHoverColor !== undefined && componentData.submitButtonHoverColor !== "") {
      setSubmitButtonHoverColor(componentData.submitButtonHoverColor);
    }

    
    const getGeoInfo = async () =>
    {
      fetch("https://ipapi.co/json/")
      .then(res => res.json())
      .then(
        (result) => {
          // setCountry(result.country_name);
        },
        (error) => {}
      )
    }

    getGeoInfo();

    let getLang = cookies.get("Language");

    const script = document.createElement('script');

    script.src = getLang!==undefined && getLang!=="" ? "https://www.google.com/recaptcha/api.js?hl="+getLang : "https://www.google.com/recaptcha/api.js"
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }

  },[]);

  useEffect(()=>{
    if(clickedSubmit) {
      setTimeout(() => {
        setClickedSubmit(false)
      }, 3000);
    }
  },[clickedSubmit]);
  
  const locationHandler = (e:any) => {
    setLocationOptionsLoading(true)
    clearTimeout(timerID);
    const id = setTimeout(() => {
      getLatLng(e);
    }, 1000);
    setTimerID(id)
  }

  const getLatLng=async(postalZip:any)=>
  {
    if(postalZip!="")
    {
     await fetch(api+postalZip+"&rnum=5&rad=100?date="+Date().toLocaleString())
      .then(res => res.json())
      .then(
        async(jsonData) => {
        if(jsonData.length>0 && jsonData!=undefined)
         {
           setLocationData(jsonData);
         }
         else{
           setLocationData([]);
         }
         setLocationOptionsLoading(false);
        },
        (error) => {}
      )
    }
    else
    {
      setLocationData([]);
    }
  }

  const setZipLocation=(event:any)=>
  { 
     event.preventDefault();
     setStoreNumber(event.target.value);
     if(event.target.value!=="" && event.target.value!==undefined)
     {
       let element = (document.getElementById(event.target.value) as HTMLInputElement);
       let selectedLocation= element.innerHTML;
       setLocation(selectedLocation); 
     }
  }


  const setSendingData=(e:any)=>
  {
    if(e.target.id=="phone")
    {
      setPhoneSendingData(e.target.value);
    }
    else if(e.target.id=="postalZip")
    {
      setZipcodeSendingData(e.target.value);   
    }
    else if(e.target.name=="location")
    {
      setStoreNumSendingData(e.target.value);
      if(e.target.value!=="" && e.target.value!==undefined)
      {
        let element = (document.getElementById(e.target.value) as HTMLInputElement);
        let selectedLocation= element.innerText;
        setLocationSendingData(selectedLocation);
      }
    }
    else if(e.target.id=="mydescription")
    {
      setDescriptionSendingData(e.target.value);
    }

    else if(e.target.name=="00N3i00000CKu6N")
    {
      setSelectedTopicSendingData(e.target.value);
    }

    else if(e.target.id=="subject")
    {
      setSubjectSendingData(e.target.value);
    }
    
    setDescription("Phone:"+phoneSendingData+", Selected Topic:"+selectedTopicSendingData+", Postal Code:"+zipcodeSendingData+", Location:"+locationSendingData+", Store Value:"+storeNumSendingData+", Subject:"+subjectSendingData+", Description:"+descriptionSendingData)
  }
 
  const handleChangeForCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
    setSendingData(event);
  };

  const validateEmail = (e: { target: { value: string; }; }) => {
    var email: string = e.target.value
  
    if (validator.isEmail(email)) {
      setEmailError('')
      setEmail(email)
    } else {
      setEmailError(emailValidation)
    }
  }

  const disableSubmit = !recaptchaChecked || emailError !== "" || clickedSubmit;

  const onSubmitButtonClicked = () => {
    setClickedSubmit(true); 
    // let element = (document.getElementById("contact-us-form") as HTMLFormElement);
    // element.submit();
  };
  
  return (
    <Fragment>
      <section className="contact-form" id="contact-form" style={{backgroundColor: backgroundColor}}>
        {backgroundImageDesktop? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImageDesktop+")"}}></div>: ""}
        {backgroundImageMobile? <div className="mobile-bg" style={{backgroundImage: "url("+backgroundImageMobile+")"}}></div>: ""}
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {subTitle ? <h6 className="d-block d-sm-none">{subTitle}</h6> : ""}
              {formLabel ? <h2>{formLabel}</h2> : "Contact Us"}
            </div>
          </div>
          <form id="contact-us-form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="post" onSubmit={onSubmitButtonClicked}>
            <div className="row">
              <div className="col-12">
                <div className="form-group noteBlock">
                  <span className="clr-white">{noteRequired?"*"+noteLabel:noteLabel}</span>
                </div>
              </div>
            </div>
            {
              disclaimerText?
                <div className="row">
                  <div className="col-12">
                    <div className="form-group noteBlock">
                      <span className="clr-white" dangerouslySetInnerHTML={{__html:disclaimerText}}></span>
                    </div>
                  </div>
                </div>
              :
              null
            }
            <input type="hidden" name="orgid" value="00D3i000000qQfL" aria-label="orgid" aria-required="true"/>
            <input type="hidden" name="retURL" value={redirectUrl?redirectUrl:window.location.hostname+"/contact-us"} aria-label="retURL" aria-required="true"/>
            <div className="row">
            <div className="col-12 col-lg-6 space-right">
                <div className="form-group">
                  <TextField 
                    id="name" 
                    name="name" 
                    label={nameLabel} 
                    variant="filled" 
                    aria-label="name" 
                    aria-required="true" 
                    className="form-control" 
                    required={nameRequired} 
                    onChange={(e)=>setName(e.target.value)} 
                    onBlur={(e)=>e.target.setCustomValidity("")} 
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 space-left">
                <div className="form-group field-wrap">
                  <TextField 
                    id="email" 
                    name="email"
                    label={emailLabel} 
                    variant="filled" 
                    aria-label="email" 
                    aria-required="true" 
                    className="form-control" 
                    required={emailRequired} 
                    onChange={(e) => validateEmail(e)}
                    helperText={emailError}
                    error={emailError !== ""}
                    onBlur={(e)=>e.target.setCustomValidity("")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 space-right">
                <div className="form-group">
                  <TextField 
                    id="phone" 
                    name="phone"
                    label={phoneLabel}
                    variant="filled" 
                    aria-label="phone" 
                    aria-required="true" 
                    className="form-control" 
                    required={phoneRequired} 
                    onChange={(e)=>{setPhone(e.target.value);setSendingData(e)}}
                    onBlur={(e)=>{e.target.setCustomValidity("");setSendingData(e)}}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 space-left">
                <FormControl variant="filled" className="form-group">
                  <InputLabel id="simple-select-outlined-label">{categoryRequired==true? categoryLabel+" *" :categoryLabel}</InputLabel>
                  <Select
                    labelId="simple-select-outlined-label"
                    id="00N3i00000CKu6N"
                    name="00N3i00000CKu6N"
                    label="Select a Topic"
                    required={categoryRequired} 
                    onChange={handleChangeForCategory}
                  >
                    {
                      topicsList.length>0?topicsList.map((item:any)=>
                      {
                        return <MenuItem value={item}>{item}</MenuItem>
                      })
                    : 
                      null
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
             <div className="row" id="hideField">
              <div className="col-12 col-lg-6 space-right">
                <div className="form-group select-outer">
                  <label htmlFor="00N3i0000070KK9">Brand</label>
                  <select  id="00N3i0000070KK9" name="00N3i0000070KK9" title="Brand" onChange={(e)=>setBrand(e.target.value)} value={brand}>
                    <option value="" disabled selected>{brandLabel ? brandLabel :"Select Brand"}</option>
                    <option value={brand}>{brand}</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6 space-left">
                <div className="form-group select-outer">
                  <label htmlFor="00N3i0000070KK8">Select Country</label>
                  <select  id="00N3i0000070KK8" name="00N3i0000070KK8" title="Country" onChange={(e)=>setCountry(e.target.value)} value={country}>
                    <option value="" disabled selected>{countryLabel ? countryLabel :"Select Country"}</option>
                    <option value={country}>{country}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 space-right">
                <div className="form-group">
                  <TextField 
                    id="postalZip"
                    aria-label="postalcode"
                    variant="filled" 
                    aria-required="true"
                    name="postal_code"
                    className="form-control"
                    label={postalZipLabel}
                    required={postalZipRequired} onChange={(e)=>{locationHandler(e.target.value);setPostalZip(e.target.value);setSendingData(e)}} 
                    onBlur={(e)=>{e.target.setCustomValidity("");setSendingData(e)}}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 space-left">
                <FormControl variant="filled" className="form-group">
                  <InputLabel>{locationLabel}</InputLabel>
                  <Select
                    disabled={locationOptionsLoading}
                    name="location"
                    required={locationRequired}
                    title="location"
                    onChange={(e)=>{setZipLocation(e);setSendingData(e)}}
                  >
                    {
                      locationData.length>0?locationData.map((item:any)=>
                      {
                        return <MenuItem key={item.id} id={item.fid} value={item.fid}>{item.location_name+" - "+item.address_1+", "+item.city+", "+item.region+", "+item.country}</MenuItem>
                      })
                    : 
                      null
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <TextField 
                    variant="filled" 
                    id="subject"
                    name="subject"
                    aria-label="subject"
                    aria-required="true"
                    className="form-control"
                    label={subjectLabel} 
                    onChange={(e)=>{setSubject(e.target.value);setSendingData(e)}} 
                    required={subjectRequired} onBlur={(e)=>{e.target.setCustomValidity("");setSendingData(e)}}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group textarea">
                  <TextField 
                    variant="filled" 
                    name="mydescription"
                    id="mydescription"
                    aria-label="formdescription"
                    aria-required="true"
                    multiline
                    rows={4}
                    required={descriptionRequired} 
                    label={descriptionLabel} 
                    onChange={(e)=>{setMyDescription(e.target.value);setSendingData(e)}} 
                    onBlur={(e)=>{e.target.setCustomValidity("");setSendingData(e)}}
                  />
                </div>
              </div>
            </div>
              <div className="row" id="hideDescription">
              <div className="col-12">
                <div className="form-group textarea">
                  <textarea name="description" id="description" aria-label="description" aria-required="true" required={descriptionRequired} placeholder={descriptionRequired==true?descriptionLabel+"*":descriptionLabel} value={description} onBlur={(e)=>e.target.setCustomValidity("")}></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div 
                className="col-12 text-center" 
                css={css`
                  input:active,
                  input:hover { 
                    background: ${submitButtonHoverColor} !important;  
                    borderColor: ${submitButtonHoverColor}; 
                  }`
                }
              >
                <div className="g-recaptcha" data-callback="recaptchaCallback" data-sitekey={captchaSiteKey} data-hl="es"></div><br/>
                <input type="submit" aria-label="submit form" className="btn btn-primary" name="submit" style={{ background: submitButtonBackgroundColor, borderColor: submitButtonHoverColor }} disabled={disableSubmit} value={submitLabel || "Submit"} />
              </div>
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

ContactUsForm.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { ContactUsForm };

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm);